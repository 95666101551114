//welcome
.welcome {
  float: right;
  padding: 27px 0 27px 40px;
  white-space: nowrap;
  @include media('<phone') {
    padding: 20px 0 20px 40px;
  }
  .text {
    display: inline-block;
    vertical-align: middle;
    color: $gull-gray;
    @include media('<phone') {
      display: none;
    }
  }
  .ll-drop {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    &.hover {
      .drop {
        display: block;
      }
    }
    .opener {
      color: $tundora;
      position: relative;
      padding-right: 20px;
      font-weight: 600;
      @include media('<phone') {
        @include size(40px);
        padding-right: 0;
        border-radius: 3px;
        color: $white;
        background:$yellow;
        display: inline-block;
        vertical-align: top;
        line-height: 1;
        @include animate(background);
        &:hover {
          background: #ffc22e;
          text-decoration: none;
        }
        .name {
          display: none;
        }
        .icon-user {
          display: inline-block;
        }
        &::after {
          display: none;
        }
      }
      .icon-user {
        display: none;
        vertical-align: top;
        position: absolute;
        top: 50%;
        left: 50%;
        line-height: 1;
        font-size: 24px;
        margin: -12px 0 0 -12px;
      }
      &:hover {
        text-decoration: none;
      }
      &::after{
        content:'';
        position: absolute;
        right: 0;
        top: 50%;
       @include triangle(9px, $tundora, down);
      }
    }
    .drop {
      position: absolute;
      font-size: 16px;
      display: none;
      right: -10px;
      top: 100%;
      padding-top: 19px;
      @include media('<phone') {
        right: 0;
      }
      &:after{
        content:'';
        @include triangle(20px, $white, up);
        position: absolute;
        right: 15px;
        top: 10px;
        margin-right: -9px;
        z-index: 6;
        @include media('<phone') {
          right: 20px;
        }
      }
      &:before{
        content:'';
        @include triangle(24px, rgba(70, 70, 70, .1), up);
        position: absolute;
        right: 15px;
        top: 8px;
        margin-right: -11px;
        z-index: 1;
        @include media('<phone') {
          right: 20px;
        }
      }
      .box {
        background: $white;
        text-align: right;
        position: relative;
        z-index: 5;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 2px 7px rgba(0, 0, 0, .45);
      }
      a{
        min-width: 120px;
      }
    }
  }
}