// filter bar
.filter-bar {
  display: flex;
  width: 100%;
  padding: 0 10px;
  @include media('<tablet') {
    flex-direction: column;
  }
  .left {
    flex-grow: 1;
  }
  .right {
    flex-grow: 0;
    @include media('<tablet') {
      padding-top: 10px;
    }
  }
}

.btn + .filter-form {
  padding-left: 14px;
  @include media('<tablet') {
    padding: 10px 0 0;
  }
}
.filter-form {
  display: inline-block;
  vertical-align: top;
  @include media('<tablet') {
    display: block;
  }
  .holder {
    display: inline-block;
    vertical-align: top;
    position: relative;
  }
  input {
    padding: 5px 40px 5px 15px;
    min-width: 320px;
    font-size: 16px;
    @include media('<desktop') {
      min-width: 240px;
    }
    @include media('<phone') {
      width: 100%;
      min-width: 1px;
    }
  }
  .icon-search {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -8px;
  }
}