// box
.box{
  margin-bottom: 20px;
  display: block;
  box-shadow: 0 2px 2px rgba(21, 67, 96, .25);
  border-radius: 5px;
  background: $white;
  &-heading {
    border-radius: 5px 5px 0 0;
    background: #154360;
    padding: 10px 20px;
    display: flex;
    @include media('<phone') {
      flex-direction: column;
    }
    .title{
      flex-grow: 1;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 30px;
      margin: 0;
      color: #fff;
      padding-right: 20px;
      @include media('<phone') {
        padding: 0 0 10px;
      }
    }
    .buttons {
      flex-grow: 0;
    }
    .btn{
      min-width: 70px;
      font-weight: 600;
      padding: 2px 15px;
      & + .btn {
        margin-left: 10px;
        margin-right: -4px;
      }
    }
  }
  &-content {
    padding: 20px;
    & + .box-heading {
      border-radius: 0;
    }
  }
  &-footer {
    display: flex;
    padding: 0 20px 20px;
    .left {
      flex-grow: 1;
    }
    a {
      line-height: 40px;
    }
    .right{
      .btn + .btn {
        margin-left: 10px;
      }
    }
  }
}
