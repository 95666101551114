// lightbox
.lightboxes {
  position: absolute;
  z-index: 1001;
  height: 0;
  width: 100%;
  left: -9999px;
  top: -9999px;
}
.lightbox-overlay {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(70, 70, 70, .5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  overflow-Y: auto;
  padding: 40px 0;
  @include media('<tablet') {
    justify-content: flex-start;
    flex-direction: column;
  }
  .lightbox {
    @extend %box;
    width: 500px;
    padding: 40px 20px 20px;
    position: relative;
    @include media('<tablet') {
      width: 88%;
    }
  }
  .close {
    font-size: 24px;
    padding: 0 5px;
    min-width: 1px;
    line-height: 1;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .row{
    padding-bottom: 15px;
    label {
      display: block;
      padding-bottom: 5px;
    }
    input, select, .jcf-select{
      width: 100%;
    }
  }
}
body.no-scroll {
  overflow: hidden;
  height: 100%;
}

//add-contract
#add-contract{
  .lightbox{
    width: 800px;
    @include media('<desktop') {
      width: 90%;
    }
  }
  input,
  select,
  .jcf-select {
    width: 100%;
    display: block;
    min-width: 100px;
  }
  .row{
    display: flex;
    margin-left: -10px;
    padding: 0;
    @include media('<tablet') {
      flex-wrap: wrap;
      padding-bottom: 20px;
    }
    .col{
      flex-grow: 1;
      flex-basis: 0;
      padding: 0 0 10px 10px;
      @include media('<tablet') {
        flex-basis: 100%;
      }
      &:last-child {
        flex-grow: 0;
      }
      &:first-child {
        flex-grow: 1.5;
      }
    }
  }
  .remove-row,
  .add-row {
    font-size: 24px;
    line-height: 24px;
    min-width: 0;
    padding: 7px 14px;
  }
  [disabled] {
    background: $white;
  }
  .buttons {
    .btn{
      margin-right: 6px;
    }
  }
}