// form-details
.form-details{
  label{
    @extend .ellipsis;
    display: block;
    line-height: 30px;
    color: $gull-gray;
  }
  h4{
    line-height: 40px;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
  input, select, .jcf-select{
    width: 100%;
  }
  .value {
    line-height: 30px;
    white-space: nowrap;
    @extend .ellipsis;
    width: 100%;
  }
}

.form-grid{
  display: table;
  width: 100%;
  .form-grid-row{
    display: table-row;
    &.group-title-row .cell-h{
      h4{
        margin-right: -50%;
      }
      padding-bottom: 0;
    }
  }
  .cell {
    display: table-cell;
    vertical-align: middle;
    padding-bottom: 10px;
    @include media('<desktop') {
      display: block;
      &:first-child {
        padding-bottom: 0;
      }
    }
    &:first-child {
      padding-right: 20px;
      width: 1%;
      @include media('<desktop') {
        width: auto;
      }
    }
  }
}
.form-details.full-width{
  display: flex;
  overflow: hidden;
  margin: 0 -20px;
   @include media('<widescreen') {
    flex-wrap: wrap;
    margin: -20px -20px 0;
   }
  .col{
    flex-basis: 25%;
    max-width: 25%;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 20px;
    min-width: 1;
    @include media('<widescreen') {
      flex-shrink: 0;
      flex-basis: 50%;
      max-width: 50%;
      padding: 20px 20px 0;
    }
    @include media('<tablet') {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
  .form-grid {
    //table-layout: fixed;
    .cell {
      //display: block;
      vertical-align: top;
      &:not(.cell-h):first-child{
        padding-bottom: 10px;
        width: 1%;
      }
      &:first-child:nth-last-child(2) {
        padding-bottom: 0;
      }
      @include media('<desktop') {
        display: table-cell;
      }
      @include media('<tablet') {
        display: block;
        width: auto !important;
      }
    }
  }
  .value {
    width: auto;
    white-space: normal;
  }
}
