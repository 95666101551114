#header {
  border-bottom: 1px solid $gallery;
  background: $white;
  position: relative;
  z-index: 5;
  .nav-active & {
    z-index: 20;
  }
}

.logo {
  float: left;
  width: 131px;
  height: 37px;
  margin-top: 23px;
}


.main-nav {
  float: right;
  @include media('<desktop') {
    display: none;
    background: #fff;
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    padding-top: 100px;
  }
  .nav-active & {
    display: block;
  }
}

.main-nav-list {
  @extend %listreset;
  float: left;
  color: $tundora;
  @include media('<desktop') {
    float: none;
  }
  > li {
    float: left;
    background: $white;
    position: relative;
    @include animate(background);
    @include media('<desktop') {
      float: none;
      font-size: 22px;
      text-align: center;
    }
    &:after{
      @include animate(width left);
      content:'';
      height: 2px;
      width: 0;
      left: 50%;
      background: $yellow;
      position: absolute;
      bottom: -1px;
      @include media('<desktop') {
        display: none;
      }
    }
    &.active,
    &.hover {
      &:after{
        width: 100%;
        left: 0;
      }
      >a{
        text-decoration: none;
        
      }
    }
    &.active > a{
      font-weight: 600;
    }
    &.hover{
      background: #ecf1f3;
      .nav-drop{
        display: block;
      }
    } 
    >a {
      display: block;
      color: inherit;
      padding: 28px 17px 28px;
      position: relative;
      @include media('<desktop') {
        padding: 28px 10px 28px;
      }
      @include media('<tablet') {
        display: inline-block;
        vertical-align: top;
      }
      
      &:hover {
        text-decoration: none;
      }
    }
  }
}


.nav-drop{
  position: absolute;
  font-size: 16px;
  display: none;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  padding-top: 19px;
  @include media('<desktop') {
    position: static;
    transform: none;
    padding: 0;
    font-size: 18px;
  }
  &:after{
    content:'';
    @include triangle(20px, $white, up);
    position: absolute;
    left: 50%;
    top: 10px;
    margin-left: -5px;
    z-index: 6;
    @include media('<desktop') {
      display: none;
    }
  }
  &:before{
    content:'';
    @include triangle(24px, rgba(70, 70, 70, .1), up);
    position: absolute;
    left: 50%;
    top: 8px;
    margin-left: -7px;
    z-index: 1;
    @include media('<desktop') {
      display: none;
    }
  }
  .box {
    background: $white;
    position: relative;
    z-index: 5;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 7px rgba(0, 0, 0, .45);
    @include media('<desktop') {
      box-shadow: none;
      border-radius: 0;
      border: solid $yellow;
      border-width: 2px 0;
    }
  }
}

.nav-list {
  @extend %listreset;
  padding: 15px 0;
  a{
    color: inherit;
    white-space: nowrap;
    display: block;
    padding: 5px 18px 5px 15px;
    &:hover {
      text-decoration: none;
      background: #ecf1f3;
    }
  }
}

.nav-app-list {
  @extend %listreset;
  padding: 20px 35px 20px 0;
  @include media('<tablet') {
    padding: 15px 15px 15px 0;
  }
  line-height: 20px;
  column-count: 4;
  @include media('<desktop') {
    column-count: 2;
  }
  @include media('<tablet') {
    column-count: 1;
  }
  li {
    display: inline-block;
    vertical-align: top;
    padding-left: 15px;
    @include media('<tablet') {
      padding-left: 15px;
      width: 50%;
      margin-right: -4px;
    }
    @include media('<phone') {
      padding-left: 15px;
      width: 100%;
    }
  }
  a{
    display: table;
    table-layout: fixed;
    padding: 20px;
    border-radius: 5px;
    color: inherit;
    &:hover {
      text-decoration: none;
      background: #ecf1f3;
    }
  }
  .image{
    display: table-cell;
    vertical-align: middle;
    padding-right: 10px;
    width: 55px;
    min-width: 55px;
    img {
      vertical-align: middle;
      display: inline-block;
    }
  }
  .description {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    width: 90%;
    span {
      display: block;
      white-space: nowrap;
    }
    b {
      font-weight: 600;
      display: block;
      white-space: nowrap;
    }
  }
}

.nav-drop-footer{
  background: #e8eef2;
  border-radius: 0 0 5px 5px;
  padding: 13px;
  white-space: nowrap;
  ul{
    @extend %listreset;
    text-align: center;
    li {
      display: inline;
      @include media('<tablet') {
        display: block;
        padding: 5px 0;
      }
      & + li {
        margin-left: 5px;
        @include media('<tablet') {
          margin: 0;
        }
        &::before {
          content: '|';
          display: inline-block;
          margin-right: 9px;
          @include media('<tablet') {
            display: none;
          }
        }
      }
    }
  }
}


/* burger menu style */
.nav-opener {
  position: relative;
  z-index: 20;
  float: right;
  width:40px;
  height:40px;
  margin-top: 20px;
  margin-left: 20px;
  background: $yellow;
  position:relative;
  border-radius:3px;
  display: none;
  @include animate(background);
  &:hover {
    background: #ffc22e;
    text-decoration: none;
  }
  @include media('<desktop') {
    display: block;
  }
    .nav-active & {
      position:fixed;
      top: 0;
      right: 20px;
     span {
       opacity: 0;
     }
     &:before,
     &:after {
       transform:rotate(45deg);
       top:50%;
       left:15%;
       right:15%;
     }
     &:after {
       transform:rotate(-45deg);
     }
    }
  span,
   &:before,
   &:after {
     background:#fff;
     border-radius:4px;
     position:absolute;
     top:50%;
     left:20%;
     right:20%;
     height:3px;
     margin-top:-2px; /* height/2 */
     transition:all .3s linear;
   }
   &:before,
   &:after {
     content:'';
     top:30%;
   }
   &::after {
     top:70%;
   }
}
