// Typography

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.h {
  font-family: $headings-font-family;
  font-weight: 600;
  margin: 0 0 0.5em;
  letter-spacing: 1px;
  color: $headings-color;
}
ol, ul {
  margin: 0 0 $vertical-rhythm;
}

h1, .h1 {
  font-size: $h1-font-size;
}

h2, .h2 {
  font-size: $h2-font-size;
}

h3, .h3 {
  font-size: $h3-font-size;
}

h4, .h4 {
  font-size: $h4-font-size;
}

h5, .h5 {
  font-size: $h5-font-size;
}

h6, .h6 {
  font-size: $h6-font-size;
}

p {
  margin: 0 0 $vertical-rhythm;
}

a {
  color: $base-link-color;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

%h2 {
  font-size: 40px;
  font-weight: 300;
  line-height: 1.25;
}
.h2 {
  @extend %h2;
}

%h3 {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.25;
  text-transform: uppercase;
}
.h3 {
  @extend %h3;
}

%underlined {
  color: inherit;
  text-decoration: underline;
  @include animate(color);
  &:hover{
    color: #3e88d8;
  }
}
.underlined {
  @extend %underlined;
}