// paging
.paging-holder{
  @extend %clearfix;
  text-align: right;
  padding: 10px 0 20px;
  @include media('<tablet') {
    text-align: center;
  }
}
.total {
  line-height: 40px;
  @include media('<tablet') {
    display: none;
  }
}
.paging {
  @extend %box;
  float: left;
  border: 1px solid #dcdee3;
  @include media('<tablet') {
    float: none;
    display: inline-block;
  }
  .total {
    line-height: 38px;
  }
  .number{
    float: left;
    padding: 7px;
    min-width: 38px;
    text-align: center;
    border-left: 1px solid #dcdee3;
    text-decoration: none;
    color: inherit;
    &:first-child {
      border: none;
    }
    &.current,
    &:hover {
      background: #f7f9fa;
    }
    &.disabled {
      color: #dcdee3;
      pointer-events: none;
    }
  }
  @include media('<tablet') {
    .number:not(.current):not(.arrow) {
      display: none;
    }
  }
}