@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?in1umv');
  src:  url('../fonts/icomoon.eot?in1umv#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?in1umv') format('truetype'),
    url('../fonts/icomoon.woff?in1umv') format('woff'),
    url('../fonts/icomoon.svg?in1umv#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%icomoon {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user:before {
  content: "\e90f";
}
.icon-no:before {
  content: "\e90d";
}
.icon-yes:before {
  content: "\e90e";
}
.icon-select-arrows:before {
  content: "\e90c";
}
.icon-arrow-right:before {
  content: "\e90a";
}
.icon-arrow-left:before {
  content: "\e90b";
}
.icon-envelope:before {
  content: "\e909";
}
.icon-cloud-download:before {
  content: "\e908";
}
.icon-linkedin:before {
  content: "\e900";
}
.icon-twitter:before {
  content: "\e901";
}
.icon-google-plus:before {
  content: "\e902";
}
.icon-facebook-square:before {
  content: "\e903";
}
.icon-social-twitter:before {
  content: "\e904";
}
.icon-social-linkedin:before {
  content: "\e905";
}
.icon-social-facebook:before {
  content: "\e906";
}
.icon-search:before {
  content: "\e907";
  color: #474747;
}
