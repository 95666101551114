/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/33f36c");

@font-face {
  font-family: 'proxima_nova';
  src: url('../fonts/33F36C_9_0.woff2') format('woff2'),
       url('../fonts/33F36C_9_0.woff') format('woff'),
       url('../fonts/33F36C_9_0.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_nova';
  src: url('../fonts/33F36C_10_0.woff2') format('woff2'),
  url('../fonts/33F36C_10_0.woff') format('woff'),
  url('../fonts/33F36C_10_0.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'proxima_nova';
  src: url('../fonts/33F36C_3_0.woff2') format('woff2'),
  url('../fonts/33F36C_3_0.woff') format('woff'),
  url('../fonts/33F36C_3_0.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}


$breakpoints: (
  'xs-phone': 320px,
  'phone': 480px,
  'tablet': 768px,
  'desktop': 1024px,
  'widescreen': 1332px
);

// keywords
$media-expressions: (
  'screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

// ligatured operators ≥ ≤

@import 'vendors/icomoon';
@import 'vendors/include-media';
@import 'vendors/normalize';

@import 'base/variables';
@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';
@import 'base/reset';
@import 'base/typography';
@import 'base/forms';


@import 'components/app-list';
@import 'components/box';
@import 'components/datepicker';
@import 'components/header';
@import 'components/filter-bar';
@import 'components/form-details';
@import 'components/form-settings';
@import 'components/info';
@import 'components/login';
@import 'components/lightbox';
@import 'components/note';
@import 'components/paging';
@import 'components/tables';
@import 'components/tickets';
@import 'components/welcome';


// for js

/* slideshow basic styles */
div.slideshow.not-enough-slides a.btn-prev,
div.slideshow.not-enough-slides a.btn-next {
  visibility: hidden;
}
/* tabset required styles for hidden tabs */
.js-tab-hidden {
  display:block !important;
  left:-9999px !important;
  position: absolute !important;
  top:-9999px !important;
}

.stacktable.large-only {
  display: table;
  @include media('<tablet') {
    display: none;
  }
}
.stacktable.small-only { 
  display: none;
  @include media('<tablet') {
    display: table; 
  }
}

.nav-active{
  height: 100%;
  overflow: hidden;
}

// for js end

html{
  height: 100%;
}
body{
  @include media('<tablet') {
    font-size: 18px;
  }
}

* {outline: none !important;}

main{
  padding: 20px 0 30px;
}

.container{
  @extend %clearfix;
  max-width: 1330px;
  padding: 0 15px;
  margin: 0 auto;
}

.two-columns {
  display: flex;
  flex-wrap: nowrap;
  @include media('<tablet') {
    flex-direction: column;
  }
  @include media('<tablet') {
    flex-direction: column;
  }
  .column-1 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 420px;
    @include media('<desktop') {
      flex-basis: 280px;
    }
    @include media('<tablet') {
      flex-basis: auto;
    }
  }
  .column-2 {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 880px;
    padding-left: 20px;
    @include media('<tablet') {
      padding: 0;
    }
  }
}

// responsive video
.responsive-video {
  height: 0;
  padding-top: 56%; // 9/16*100%
  position: relative;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
