// info
.info {
  margin-bottom: 30px;
  h2{
    font-size: 24px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 0.3em;
  }
  .box-content{
    display: flex;
    @include media('<tablet') {
      flex-direction: column;
    }
  }
  .left{
    flex-grow: 1;
  }
  .right{
    flex-grow: 0;
  }
  .buttons {
    padding-top: 0.5em;
    @include media('<tablet') {
      padding-bottom: 0.5em;
    }
  }
  .btn{
    min-width: 70px;
    font-weight: 600;
    padding: 2px 15px;
    & + .btn {
      margin-left: 10px;
      margin-right: -4px;
    }
  }
}
.info-table{
  display: table;
  .row {
    display: table-row;
  }
  .cell{
    display: table-cell;
    min-width: 143px;
    vertical-align: top;
    white-space: nowrap;
    padding: 3px 15px 3px 0;
    @include media('<phone') {
      display: block;
      padding-right: 0;
      padding-top: 0;
    }
    &-h{
      color: $gull-gray;
      min-width: 1px;
      @include media('<phone') {
        padding-bottom: 0;
        padding-top: 6px;
      }
    }
  }
}