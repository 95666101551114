// login
.login-page{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: 40px 20px;
  h1{
    @extend %h2;
  }
  .login-container {
    width: 360px;
    text-align: center;
    @include media('<phone') {
      width: 100%;
    }
  }
  .login-logo {
    display: inline-block;
    margin-bottom: 20px;
    width: 200px;
    img {
      width: 100%;
    }
  }
  .box {
    padding: 20px;
    width: 100%;
  }
  .row {
    text-align: left;
    padding-bottom: 10px;
    &.or {
      padding: 10px 0 20px;
      text-align: center;
    }
  }
  input,
  .btn {
    display: block;
    width: 100%;
  }
  .btn {
    i,
    .text {
      display: inline-block;
      vertical-align: middle;
    }
    i{
      margin-right: 5px;
      font-size: 18px;
    }
    &.btn-fb{
      background: #3b579b;
      border: 1px solid #3b579b;
      color: $white;
      @include animate(background);
      &:hover{
        background: lighten(#3b579b, 10%);
      }
    }
    &.btn-gp{
      background: #dd4f42;
      border: 1px solid #dd4f42;
      color: $white;
      @include animate(background);
      &:hover{
        background: lighten(#dd4f42, 10%);
      }
    }
  }
}