// app-list
.app-list {
  @extend %listreset;
  margin-top: -45px;
  padding-bottom: 15px;
  &.disabled {
    pointer-events: none;
    .btn-default {
      border: 1px solid #8ba2ae;
      color: #8ba2ae;
    }
    .btn-blue {
      border: 1px solid #8ba2ae;
      background: #8ba2ae;
      color: #c1cdd4;
    }
    .image img{
      filter: grayscale(1);
      opacity: .5;
    }
  }
  li{
    display: inline-block;
    vertical-align: top;
    width: 25%;
    text-align: center;
    padding-top: 60px;
    margin-right: -4px;
    @include media('<desktop') {
      width: 50%;
    }
    @include media('<phone') {
      width: 100%;
    }
  }
  .image {
    margin: 0 auto;
    max-width: 60px;
    min-height: 70px;
    img{
      width: 100%;
    }
  }
  .description{
    line-height: 20px;
    span {
      font-size: 14px;
      display: block;
    }
    b{
      display: block;
      font-weight: 600;
    }
  }
  
  .buttons {
    padding: 15px 0;
  }
  .note {
    color: $gull-gray;
    line-height: 20px;
    p{
      margin: 0;
    }
  }
}