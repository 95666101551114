.responsive-table{
  width: 100%;
  overflow: auto;
  padding-bottom: 10px;
}

// responsive table
.table { min-width: 1000px;}
.stacktable { min-width: 200px;}
.stacktable,
.table{
  width: 100%;
  tr {
    border-bottom: 1px solid #f7f9fa;
    &:not(:first-child):hover{
      background: #f7f9fa;
    }
    &.group{
      border-bottom: 1px solid $curious-blue;
      td{
        font-weight: 600;
        padding-top: 30px;
        padding-bottom: 14px;
      }
    }
    &:first-child{
      border-bottom: 1px solid $curious-blue;
    }
  }
  tr:last-child{
    border: none;
  }
  .icon-yes {
    color: #76b72a;
    position: relative;
    top: 3px;
  }
  .icon-no {
    color: #c0c0c0;
    position: relative;
    top: 3px;
  }
  td, th{
    padding: 12px 10px;
    
    vertical-align: middle;
    text-align: left;
  }
  .cell-text {
    @extend .ellipsis;
    max-width: 200px;
  }
}

.stacktable.small-only tr:hover{
  background: none;
}
.stacktable.small-only tr:first-child {
  border: none;
}
.stacktable.small-only .st-head-row-main {
  display: none;
}
.stacktable.small-only .st-head-row {
  border-bottom: 1px solid $curious-blue;
}

.t-label{
  vertical-align: middle;
  display: inline-block;
  padding-right: 18px;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  &.active{
    .sort-marker {
      display: block;
    }
  }
  .column-name{
    display: inline-block;
    vertical-align: middle;
  }
  .sort-marker{
    position: absolute;
    right: 0;
    top: 50%;
    display: inline-block;
    display: none;
    &.up {
      @include triangle(8px, $black, up);
    }
    &.down {
      @include triangle(8px, $black, down);
    }
  }
}