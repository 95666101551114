// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://chir.ag/projects/name-that-color/

$mystic: #e9eef0;
$tropical-blue: #cde9fa;
$kashmir-blue: #4d7994;
$gallery: #ececec;
$tundora: #474747;
$black: #222;
$bunker: #0d141a;
$white: #fff;
$blue: #3e88d8;
$yellow: #ffaa00;
$slate-gray: #798a94;
$mirage: #18252e;
$gray: #b2b2b2;
$curious-blue: #319de0;
$gull-gray: #95a4ad;

$base-font-sans-serif: 'proxima_nova', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$base-font-serif: 'Times New Roman', 'Times', 'Baskerville', 'Georgia', serif;

// Body
$base-text-color: $black !default;
$base-background-color: #f7f9fa !default;
$base-font-size: 16px !default;
$base-line-height: 1.56 !default;
$base-font-family: $base-font-sans-serif !default;

$vertical-rhythm: ($base-line-height * 1em);

$base-min-width: 320px;

// Liks
$base-link-color: $blue;

// Buttons 
$btn-color: $white !default;
$btn-background: $black !default;
$btn-padding: 11px 20px !default;
$btn-font-size: inherit !default;

// Forms
$form-element-padding: $btn-padding !default;
$form-element-border-color: $gallery !default;
$form-element-focus-border-color: rgb(183, 212, 232) !default;
$placeholder-color: #87898a !default;

// Headers
$h1-font-size: 24px !default;
$h2-font-size: 22px !default;
$h3-font-size: 20px !default;
$h4-font-size: 18px !default;
$h5-font-size: 17px !default;
$h6-font-size: 16px !default;

$headings-font-family: inherit !default;
$headings-color: inherit !default;


$animation-speed: 0.3s;
