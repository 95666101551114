// list reset
%listreset {
  margin: 0;
  padding: 0;
  list-style: none;
}

// clearfix
%clearfix {
 &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.clearfix {
  @extend %clearfix;
}


// transition
%transition {
 transition: all 0.3s ease-in-out;
}

// justify nav
%justify {
 text-align: justify;
 font-size: 1px;
 line-height: 0px;
 > * {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  font-size: $base-font-size;
  line-height: $base-line-height;
 }
 &:after {
  content: '';
  width: 100%;
  display: inline-block;
  vertical-align: top;
 }
}



/// * Helper class to truncate and add ellipsis to a string too long for it to fit
/// * on a single line.
/// * 1. Prevent content from wrapping, forcing it on a single line.
/// * 2. Add ellipsis at the end of the line.

.ellipsis {
  white-space: nowrap; // 1 //
  text-overflow: ellipsis; // 2 //
  overflow: hidden;
}


%box {
  box-shadow: 0 2px 2px rgba(21, 67, 96, .25);
  border-radius: 5px;
  background: $white;
}

%hide-from-desktop {
  @include media('<desktop') {
    display: none;
  }
}
%hide-from-phone{
  @include media('<phone') {
    display: none;
  }
}
%show-from-phone{
  @include media('<phone') {
    display: inline-block;
  }
}

%section-w-bg {
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  color: $white;
}
.section-w-bg {
  @extend %section-w-bg;
}

%section-heading {
  text-align: center;
  margin: 0 auto;
  max-width: 860px;
  padding-bottom: 30px;
  h2 {
    @extend %h2;
    margin-bottom: 1em;
  }
  h3{
    @extend %h3;
    margin-bottom: 1em;
  }
  a{
    @extend %underlined;
  }
}
.section-heading {
  @extend %section-heading;
}