// settings
.form-settings {
  .col {
    display: inline-block;
    vertical-align: top;
    width: 45%;
    margin-right: -4px;
    @include media('<desktop') {
      width: 100%;
    }
    & + .col {
      padding-left: 40px;
      width: 55%;
      @include media('<desktop') {
        width: 100%;
        padding: 40px 0 0;
      }
    }
    h4{
      font-size: 16px;
      margin-bottom: 1em;
    }
  }
  .row {
    display: flex;
    @include media('<phone') {
      flex-direction: column;
    }
    & + .row {
      padding-top: 20px;
    }
  }
  .label-holder {
    flex-basis: 220px;
    padding-right: 20px;
    padding-top: 8px;
    flex-shrink: 0;
     @include media('<phone') {
      flex-basis: 0px;
     }
  }
  .inputs-holder {
    flex-basis: 450px;
    flex-shrink: 1;
    @include media('<phone') {
      flex-basis: 0px;
     }
  }
  
  input, select, textarea, .jcf-select{
    width: 100%;
  }
  .c-checkbox{
    margin-top: 5px;
  }
}
