// forms

form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
//input[type='url'],
// input[type='date'],
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  box-sizing: border-box;
  border: 1px solid $form-element-border-color;
  padding: $form-element-padding;
  @include animate(border-color);
  border-radius: 5px;
  font-size: 14px;
  color: $black;

  // &:hover {

  // }

  &:focus {
    border-color: $form-element-focus-border-color;
    box-shadow: 0 0px 3px rgba(128, 185, 224, 0.45);
  }

  @include placeholder {
    color: $placeholder-color;
  }
}

select {
  -webkit-border-radius: 0;
  color: #b9bcbd;
}

textarea {
  resize: vertical;
  vertical-align: top;
}

button,
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='submit'] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  cursor: pointer;
}

.btn {
  display: inline-block;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  padding: 7px 20px;
  border: 1px solid #154360;
  color: #154360;
  background: $white;
  font-weight: 600;
  //min-width: 224px;
  min-width: 70px;
  @extend %transition;
  &[disabled] {
    opacity: .5;
    pointer-events:none;
  }
  &:hover {
    text-decoration: none;
  }
  &.btn-yellow{
    background: $yellow;
    color: $white;
    border-color: $yellow;
    &:hover {
      background: #ffc22e;
    }
  }
  &.btn-transparent{
    color: $white;
    border-color: $white;
    background: rgba(255, 255, 255, 0);
    &:hover {
      color: $black;
      background: $white;
    }
  }
  &.btn-default {
    border: 1px solid #fff;
    color: #fff;
    background: rgba(255, 255, 255, 0);
    &:hover {
      color: $white;
      background: #154360;
    }
  }
  &.btn-default-blue {
    border: 1px solid $curious-blue;
    color: $curious-blue;
    &:hover {
      color: $white;
      background: $curious-blue;
    }
  }
  &.btn-blue{
    background: #154360;
    color: $white;
    &:hover {
      background: lighten(#154360, 5%);
    }
  }
  &.btn-submit{
    background: $curious-blue;
    color: $white;
    border-color: $curious-blue;
    &:hover {
      background: lighten($curious-blue, 5%);
      border-color: lighten($curious-blue, 5%);
    }
  }
}

/* custom select styles */
.jcf-select {
  display: inline-block;
  vertical-align: top;
  position: relative;
  border: 1px solid #ebeeef;
  background: #fff;
  border-radius: 5px;
  min-width: 150px;
  height: 40px;

}
.jcf-select select {
  z-index: 1;
  left: 0;
  top: 0;
}
.jcf-select .jcf-select-text {
  text-overflow:ellipsis;
  color: $black;
  white-space: nowrap;
  overflow: hidden;
  cursor: default;
  display: block;
  font-size: 14px;
  line-height: 40px;
  margin: 0 35px 0 20px;
}
.jcf-select.jcf-placeholder .jcf-select-text {
  color: $placeholder-color;
}
.jcf-select .jcf-select-text .jcf-option-hideme{
  color: $placeholder-color;
}

.jcf-select .jcf-select-opener {
  position: absolute;
  width: 25px;
  bottom: 0;
  right: 0;
  top: 0;
  color: #87898a;
  @extend %icomoon;
  &:before {
    content: '\e90c';
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -8px;
  }
}
body > .jcf-select-drop {
  position: absolute;
  margin: -1px 0 0;
  z-index: 9999;
}
body > .jcf-select-drop.jcf-drop-flipped {
  margin: 1px 0 0;
}
.jcf-select .jcf-select-drop {
  position: absolute;
  margin-top: 0px;
  z-index: 9999;
  top: 100%;
  left: -1px;
  right: -1px;
}
.jcf-select .jcf-drop-flipped {
  bottom: 100%;
  top: auto;
}
.jcf-select-drop .jcf-select-drop-content {
  border: 1px solid #ebeeef;
  border-radius: 5px;
}
/* multiple select styles */
.jcf-list-box {
  overflow: hidden;
  display: inline-block;
  border: 1px solid #b8c3c9;
  min-width: 200px;
  margin: 0 15px;
}
/* select options styles */
.jcf-list {
  display: inline-block;
  vertical-align: top;
  position: relative;
  background: #fff;
  line-height: 1.5;
  font-size: 14px;
  width: 100%;
}
.jcf-list .jcf-list-content {
  vertical-align: top;
  display: inline-block;
  overflow: auto;
  width: 100%;
}
.jcf-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.jcf-list ul li {
  overflow: hidden;
  display: block;
}
.jcf-list .jcf-overflow {
  overflow: auto;
}
.jcf-list .jcf-option {
  white-space: nowrap;
  overflow: hidden;
  cursor: default;
  display: block;
  padding: 5px 20px;
  height: 1%;
  color: #87898a;
}
.jcf-list .jcf-disabled {
  background: #fff !important;
  color: #aaa !important;
}
.jcf-select-drop .jcf-hover,
.jcf-select-drop .jcf-selected {
  background: #ecf1f3;
  color: #222;
}
.jcf-list .jcf-optgroup-caption {
  white-space: nowrap;
  font-weight: bold;
  display: block;
  padding: 5px 9px;
  cursor: default;
  color: #000;
}
.jcf-list .jcf-optgroup .jcf-option {
  padding-left: 30px;
}
/* common custom form elements styles */
.jcf-disabled {background: #ddd !important;}
.jcf-focus, {
  box-shadow: 0 0px 3px rgba(128, 185, 224, 0.45);
  border-color: $form-element-focus-border-color;
}
.jcf-select-drop .jcf-option-hideme {
  display: none;
}

// custom checkbox
.c-checkbox{
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  position: relative;

  input {
    position: absolute;
    left: -9999px;
  }

  .fake-checkbox{
    @include size(20px);
    border: 1px solid #ebebeb;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    @extend %icomoon;
    font-size: 8px;
    &::after {
      color: $yellow;
      position: absolute;
      left: 50%;
      top: 50%;
      content: '\e90e';
      margin: -3px 0 0 -6px;
      display: none;
    }
  }
  input:checked + .fake-checkbox::after {
    display: block;
  }
}
