// tickets-list
.tickets-list {
  display: table;
  width: 100%;
  .item {
    display: table-row;
    &:first-child {
      .status,
      .description {
        padding-top: 0 !important;
      }
    }
  }
  .status {
    display: table-cell;
    vertical-align: top;
    width: 1%;
    padding-right: 20px;
    padding-top: 20px;
    @include media('<phone') {
      display: inline-block;
      width: auto;
      padding-top: 20px;
    }
  }
  .description {
    padding-top: 20px;
    display: table-cell;
    vertical-align: top;
    @include media('<phone') {
      display: block;
      padding: 0;
    }
    .meta {
      color: $gull-gray;
      padding-top: 5px;
    }
  }

  .status-label {
    @extend .ellipsis;
    display: inline-block;
    margin-top: 4px;
    max-width: 120px;
    vertical-align: top;
    white-space: nowrap;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    padding: 0 10px;
    &.status-green{
      background: #d7f2c7;
      color: #789a63;
    }
    &.status-blue{
      background: #cde9fa;
      color: #4d7994;
    }
  }
}
.tickets-list-2-cols {
  margin-left: -40px;
  overflow: hidden;
  .col{
    display: inline-block;
    vertical-align: top;
    margin-right: -4px;
    width: 50%;
    padding-left: 40px;
    @include media('<desktop') {
      width: 100%;
    }
  }
}