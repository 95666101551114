// note
.note-list {
  .item {
    @extend %clearfix;
    padding-bottom: 20px;
  }
  .close {
    float: right;
    margin-left: 40px;
  }
  .text {
    overflow: hidden;
  }
  p{
    margin-bottom: 10px;
  }
  .meta {
    color: $gull-gray;
  }
}

.close {
  display: inline-block;
  background: none;
  background-size: 100%;
  border: none;
  padding: 0;
  color: #c0c0c0;
  font-size: 16px;
  line-height: 1;
}

.add-note-form {
  @extend %clearfix;
  textarea {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }
  .btn {
    float: right;
    font-weight: 600;
  }
}